import getInitials from "../../helpers/getInitials"
import LightOrDark from '../../helpers/LightOrDark'
import logout from "../../helpers/Logout"
import React, { useState, useEffect } from "react"
import { GET } from '../../helpers/Queries'
import { NavLink } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const user = useSelector(state => state.user)
  const [ showDropdown, setShowDropdown ] = useState(false)

  const onClickLogout = ()=> {
    logout()
  }

  useEffect(()=> {
    if(showDropdown) {
      let handleClick = (event)=>setShowDropdown(false)
      setTimeout(()=>{ document.body.addEventListener('click', handleClick) }, 100)
      return ()=>document.body.removeEventListener('click', handleClick)
    }
  }, [showDropdown])

  return(
    <div className={["dropdown user-dropdown", ( showDropdown ? "show" : '' ), props.className].filter(Boolean).join(' ')}>
      <button title={ user.name } className="btn dropdown-button px-1 px-md-2 d-flex align-items-center" type="button" id="dropdownMenuButton" onClick={()=>setShowDropdown(!showDropdown)} aria-haspopup="true" aria-expanded="false">
        <div className="circular-icon me-1" style={{ left: '2px', backgroundColor: user.image ? 'rgba(0,0,0,0.3)' : 'black', backgroundImage: user.image ? `url(https://uploads.depay.com/${user.image})` : 'none', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          { !user.image &&
            <span className={ 'text-white' }>{
              user.name ? getInitials(user.name) : <i className="fa-regular fa-user"></i>
            }</span>
          }
        </div>
      </button>
      <div className={["dropdown-menu", ( showDropdown ? "show" : '' )].join(' ')} aria-labelledby="dropdownMenuButton">
        <div className="pt-2 pb-2">
          
          <div className="border-bottom pb-3">
            
            <div className="px-4">

              <h6 className="opacity-50 font-size-s">User</h6>
              <div className="opacity-50">{ user.name }</div>

            </div>

            <div className="pt-2">
              <NavLink to="/user/settings" className={()=>"dropdown-item py-1"}>
                Settings
              </NavLink>
            </div>
          </div>

        </div>

        <div>
            
          <button onClick={onClickLogout} type="button" className="dropdown-item py-1 d-flex align-items-center justify-content-between">
            <span className="font-size-s text-grey">Log out</span>
          </button>
        </div>
      </div>
    </div>
  )
}
